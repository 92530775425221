import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-info-modal',
  templateUrl: './file-info-modal.component.html',
  styleUrls: ['./file-info-modal.component.scss'],
})
//modal containing upload instructions
export class FileInfoModalComponent {
  @Input() title: string;
  @Input() message: string;

  constructor(
    public dialogRef: MatDialogRef<FileInfoModalComponent>,
    // skipcq: JS-0323
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}
}
