/**
 * Checks if a value matches a filter rule.
 * Checks T[key] against filter[key] for each key in filter.
 *
 * if T[key] is an array, it checks all elements in T[key] against filter[key] following the rules below:
 *
 * if filter[key] is an array, it checks if T[key] is in filter[key].
 * if filter[key] is a Date and T[key] is a number, it checks if T[key] is within the last filter[key] days.
 * otherwise, it checks if T[key] === filter[key].
 *
 * @param value
 * @param filterValues
 * @returns
 */
export function checkFilterValues(value: unknown, filterValues: unknown) {
  if (Array.isArray(filterValues)) {
    return filterValues.includes(value);
  }

  // Check if the date is within the last X days
  if (value instanceof Date) {
    if (typeof filterValues === 'number') {
      const date = new Date();
      date.setDate(date.getDate() - filterValues);

      return value >= date;
    }
    if (filterValues instanceof Date) {
      return value.getTime() === filterValues.getTime();
    }
  }

  if (typeof value === 'string') {
    return value.toLowerCase().includes((filterValues as string).toLowerCase());
  } else {
    return value === filterValues;
  }
}

type FilterableValueOrDaysSince<T> = T extends Date ? number | Date : T;

// If T is an array, it will allow searching for any values in the array according to the above rules
type FilterableMatchSourceArray<T> = T extends unknown[]
  ? FilterableValueOrDaysSince<T[number]>
  : FilterableValueOrDaysSince<T>;

type FilterableMatchMultiple<T> =
  | FilterableMatchSourceArray<T>[]
  | FilterableMatchSourceArray<T>;

type FilterableValue<T> = FilterableMatchMultiple<T>;

export type Filterable<T> = {
  [key in keyof T]?: FilterableValue<T[key]>;
};

/**
 * Filters an array of objects (one level-depth) with multiple criteria.
 * Checks T[key] against filter[key] for each key in filter.
 *
 * if T[key] is an array, it checks all elements in T[key] against filter[key] following the rules below:
 *
 * if filter[key] is an array, it checks if T[key] is in filter[key].
 * if filter[key] is a Date and T[key] is a number, it checks if T[key] is within the last filter[key] days.
 *
 * @param array: the array to filter
 * @param filter: an object with the filter criteria
 * @return
 */
export function filterPlainArray<T>(array: T[], filter: Filterable<T>) {
  return array.filter((item) => {
    for (const key in filter) {
      if (Object.prototype.hasOwnProperty.call(filter, key)) {
        const value = filter[key];
        if (value === undefined || value === null) {
          continue;
        }

        const sourceValue = item[key];

        if (Array.isArray(sourceValue)) {
          if (
            sourceValue.find((v) => checkFilterValues(v, value)) !== undefined
          ) {
            continue;
          }

          return false;
        }

        if (!checkFilterValues(sourceValue, value)) {
          return false;
        }
      }
    }

    return true;
  });
}
