import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  FileContentValidationResult,
  FileUploadEvent,
  FileUploadEventData,
} from '../../components/file-upload/file-upload.component';
import { RAGApiService } from '../../services/rag-api/rag-api.service';
import {
  SpreadsheetSubmissionColumnNames,
  SpreadsheetSubmissionRow,
  spreadsheetToSubmissionDto,
  submissionDtoToSpreadsheetColumn,
} from '../../models/spreadsheet-submission-row.model';
import { UserInfoService } from '../../services/userinfo/userinfo.service';
import { MatDialog } from '@angular/material/dialog';
import { FileInfoModalComponent } from '../../components/file-info-modal/file-info-modal.component';
import { environment } from '../../../environments/environment';
import { WorkSheet, utils, WorkBook, writeFile } from 'xlsx';
import {
  BatchRecordSubmissionRow,
  OktaJwtDto,
} from '@wex-risk/rp-portfolio-utils';

@Component({
  selector: 'app-submit',
  templateUrl: './submit.component.html',
  styleUrls: ['./submit.component.scss'],
})
// Treatments submission page
export class SubmitComponent implements OnInit, AfterViewInit {
  constructor(
    private readonly ragApiService: RAGApiService,
    private readonly userInfoService: UserInfoService,
    private readonly dialog: MatDialog,
  ) {}
  user: OktaJwtDto;

  // Subscribes to user observable to set the current user once it's information has been retrieved from OKTA
  ngOnInit(): void {
    this.userInfoService.user.subscribe((user) => {
      this.user = user;
    });
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.openInfoModal();
  }

  headers = Object.values(SpreadsheetSubmissionColumnNames);

  /**
   * Opens the modal containing upload instructions
   * @returns void
   */
  openInfoModal() {
    if (new Date() > environment.infoModalDate) {
      return;
    }

    this.dialog.open(FileInfoModalComponent);
  }

  /**
   * Converts the file contents to BatchRecordSubmissionRow and sends the data to RAG API
   * @param {fileName: string, fileContents: SpreadsheetSubmissionRow[]}
   */
  async runUpload({
    fileName,
    fileContents,
  }: FileUploadEventData<SpreadsheetSubmissionRow>) {
    await this.ragApiService.submitTreatments({
      submissionName: fileName,
      rows: fileContents.map<BatchRecordSubmissionRow>((row) =>
        spreadsheetToSubmissionDto(row.data),
      ),
    });
  }

  validateTreatment = async (
    fileRow: SpreadsheetSubmissionRow,
  ): Promise<FileContentValidationResult<SpreadsheetSubmissionRow>> => {
    try {
      const result = await this.ragApiService.validateSubmissionRow(
        spreadsheetToSubmissionDto(fileRow),
      );
      if (result.status === 'success') {
        return {
          valid: true,
        };
      }

      if (!result.errors) {
        throw new Error(result.message);
      }

      const errorMap = new Map<keyof SpreadsheetSubmissionRow, string[]>();

      for (const issue of result.errors) {
        const [field] = issue.field as [keyof BatchRecordSubmissionRow];
        const rowField = submissionDtoToSpreadsheetColumn.get(field);
        if (!rowField) {
          throw new Error(`Unknown field ${String(field)}`);
        }
        if (!errorMap.has(rowField)) {
          errorMap.set(rowField, []);
        }
        errorMap.get(rowField)?.push(...issue.errors);
      }

      return {
        valid: false,
        errorMap: new Map(
          Array.from(errorMap.entries()).map(([key, value]) => [
            key,
            value.join('\n'),
          ]),
        ),
      };
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  /**
   * Handles dataSubmitted event emitted by the FileUploadComponent
   * @param event - FileUploadEvent
   */
  onDataSubmitted(event: FileUploadEvent) {
    this.runUpload(event)
      .then(() => event.onFinish())
      .catch(event.onFinish);
  }

  /**
   * Generates a template file to be used for uploading
   */
  exportTemplate() {
    const fileName = 'bulk_account_actions_template.xlsx';

    const ws: WorkSheet = utils.json_to_sheet([], {
      header: this.headers,
    });
    const wb: WorkBook = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Bulk Account Actions');
    writeFile(wb, fileName);
  }
}
