import {
  BatchRecordDto,
  BatchRecordValidationDto,
  PlatformType,
} from '@wex-risk/rp-portfolio-utils';
import { Expose, Transform, plainToInstance } from 'class-transformer';

/**
 * used to export BatchRecord into a format that can be reuploaded to the API
 * without the need to be modified by the user
 */
export class BatchRecordExport {
  @Expose({ name: 'platform' })
  platform: string;

  @Expose({ name: 'updateType' })
  update_type: string;

  @Expose({ name: 'customerId' })
  account_number: string;

  @Expose({ name: 'account_group' })
  @Transform(
    ({ obj }): string => {
      return (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (obj.platform === PlatformType.naf ? obj.bu : obj.carrierId) ??
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        obj.account_group
      );
    },
    { toClassOnly: true },
  )
  account_group?: string;

  @Expose({ name: 'creditLimit' })
  credit_limit: number;

  @Expose({ name: 'cashAdvanceLimit' })
  cash_advance_limit: number;

  @Expose({ name: 'moneyCodeLimit' })
  money_code_limit: number;

  @Expose({ name: 'comment' })
  comment: string;

  @Expose({ name: 'customerStatus' })
  customer_status: string;

  @Expose({ name: 'creditTemplateType' })
  template_type: string;

  @Expose({ name: 'firstReasonCode' })
  reason_code_1: string;

  @Expose({ name: 'secondReasonCode' })
  reason_code_2: string;

  @Expose({ name: 'thirdReasonCode' })
  reason_code_3: string;

  @Expose({ name: 'fourthReasonCode' })
  reason_code_4: string;

  @Expose({ name: 'billingCurrency' })
  billing_currency: string;

  @Expose({ name: 'status' })
  status: string;

  @Expose({ name: 'startedAt' })
  startedAt: string;

  @Expose({ name: 'completedAt' })
  completedAt: string;

  @Expose({ name: 'validations' })
  @Transform(
    ({ value }: { value: BatchRecordValidationDto[] }) => {
      if (Array.isArray(value)) {
        return value
          .map((validation) => `${validation.severity}:${validation.comment}`)
          .join(',\n');
      }
      return undefined;
    },
    { toClassOnly: true },
  )
  validations: string;

  //transforms a BatchRecordDto into BatchRecordExport
  static fromRecord(record: Partial<BatchRecordDto>): BatchRecordExport {
    return plainToInstance(BatchRecordExport, record);
  }
}
