import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy',
})
/**
 * OrderBy pipe. Sorts an array based on the propertyName providade and the direction.
 * Usage:
 * array | orderBy:propertyName:direction
 * Example:
 * array | orderBy:'name':'asc'
 */
export class OrderByPipe implements PipeTransform {
  // implements transform required by the PipeTransform interface
  // skipcq: JS-0105
  transform(
    value: { [key: string]: unknown }[],
    propName: string,
    direction?: string,
  ) {
    const sortOrder = direction ? direction : 'asc';
    if (!value) return undefined;
    return value.sort((a, b) => {
      if (a[propName] < b[propName]) {
        return sortOrder === 'asc' ? -1 : 1;
      } else if (a[propName] === b[propName]) {
        return 0;
      } else if (a[propName] > b[propName]) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return undefined;
    });
  }
}
