import { BatchDto } from '@wex-risk/rp-portfolio-utils';
import { Filterable, filterPlainArray } from 'src/app/utils/filter-plain-array';

export type FilterableBatchInformation = BatchDto;

/**
 * Converts a BatchInformationResponseDto object into FilterableBatchInformation
 * @param batch
 * @returns
 */
export function batchToFilterable(batch: BatchDto): FilterableBatchInformation {
  return batch;
}

/**
 * Converts a FilterableBatchInformation object into BatchInformationResponseDto
 * @param filterable
 * @returns
 */
export function filterableToBatch(filterable: FilterableBatchInformation) {
  return filterable;
}

// Filter batch information
export function filterBatchInformation(
  files: BatchDto[],
  filter: Filterable<FilterableBatchInformation>,
) {
  return filterPlainArray(files.map(batchToFilterable), filter).map(
    filterableToBatch,
  );
}
