import { CanActivateFn } from '@angular/router';

import { inject } from '@angular/core';
import { filter, firstValueFrom } from 'rxjs';

import { AccessRightsDto } from '@wex-risk/rp-portfolio-utils';
import { RAGApiService } from './services/rag-api/rag-api.service';

export type RoleAuthorizationGuardParams = {
  data: {
    requiredAccessRights: AccessRightsDto[];
  };
};

/**
 * Guard used to validate if the system is in maintenance mode.
 * If the maintenance mode is enabled, access to the route is denied.
 * @returns Promise that resolves to true (authorized) or false (not authorized).
 */
export const disabledProcessFileGuard: CanActivateFn = async () => {
  const ragService = inject(RAGApiService);
  try {
    const settings = await firstValueFrom(
      ragService.adminSettings$.pipe(filter((settings) => settings != null)),
    );
    if (settings?.enabled) {
      await ragService.goHome();
      return false;
    }

    return true;
  } catch (error) {
    console.error('Error fetching admin settings:', error);
    return true; // You may choose to return false based on your logic
  }
};
