import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
// Injectable class to provide the API url based on a path
export class EnvironmentService {
  private readonly apiUrl = new URL(environment.baseApiUrl);
  private readonly r360ApiUrl = new URL(environment.baseR360ApiUrl);

  get ignoreScopes(): boolean {
    return environment.ignoreScopes;
  }

  //generates the full path to the API based on the path provided and the base API URL
  apiPath(path = '', isR360 = false): string {
    return new URL(path, isR360 ? this.r360ApiUrl : this.apiUrl).toString();
  }
}
