import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
/**
 * Filter pipe. Removes elements from an array that do not match the filter criteria.
 * Usage:
 * array | filter:{key1: value1, key2: value2, ...}
 */
export class FilterPipe implements PipeTransform {
  // implements transform required by the PipeTransform interface
  // skipcq: JS-0105
  transform<T extends { [key: string]: unknown }>(
    array: T[],
    ...args: { [key: string]: unknown }[]
  ): T[] {
    //if value is a string, convert it to lowercase, else return value
    const getValue = (value: unknown) =>
      typeof value === 'string' ? value.toLowerCase() : value;

    if (Array.isArray(array) && array.length > 0 && args) {
      let returnArray = array;

      args.forEach((filter) => {
        const filterKey = Object.keys(filter)[0];
        const filterValue = filter[filterKey];
        if (Array.isArray(filterValue)) {
          array.forEach((obj) => {
            if (!filterValue.includes(obj[filterKey])) {
              returnArray = returnArray.filter((o) => o !== obj);
            }
          });
        } else {
          array.forEach((obj) => {
            if (
              getValue(obj[filterKey]) !== getValue(filterValue) &&
              filterValue !== ''
            ) {
              returnArray = returnArray.filter((o) => o !== obj);
            }
          });
        }
      });

      return returnArray;
    }
    return undefined;
  }
}
