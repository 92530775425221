import {
  ExclusionRecordDto,
  PlatformType,
  convertToPlatformType,
} from '@wex-risk/rp-portfolio-utils';

export enum SpreadsheetExclusionColumnNames {
  platform = 'platform',
  account_number = 'account_number',
  account_group = 'account_group',
  comment = 'comment',
}

export type SpreadsheetExclusionRow = {
  [key in SpreadsheetExclusionColumnNames]?: string;
};

/**
 * Transforms a SpreadsheetExclusionRow into an ExclusionRecordDto.
 * The account_number and account_group are mapped to arNumber/carrierId or customerId/businessUnit
 * depending on the platform.
 * @param spreadsheetRow
 * @returns
 */
export function spreadsheetToExclusionRow(
  spreadsheetRow: SpreadsheetExclusionRow,
): ExclusionRecordDto {
  const exclusion = <ExclusionRecordDto>{
    platform: convertToPlatformType(spreadsheetRow.platform),
    comment: spreadsheetRow.comment,
  };

  if (exclusion.platform === PlatformType.naf) {
    exclusion.customerId = spreadsheetRow.account_number;
    exclusion.businessUnit = spreadsheetRow.account_group;
  }
  if (exclusion.platform === PlatformType.otr) {
    exclusion.arNumber = spreadsheetRow.account_number;
    exclusion.carrierId = spreadsheetRow.account_group;
  }

  return exclusion;
}
