<div class="file-validation-snack-bar-container">
  <ul>
    <li *ngFor="let error of visibleErrors">{{ error }}</li>
    <li *ngIf="remainingErrors > 0">
      <a download="error_list.csv" [href]="csvDownloadLink"
        >{{ remainingErrors }} more, click here to download the full list</a
      >
    </li>
  </ul>

  <button
    mat-raised-button
    class="mat-simple-snackbar-action ng-star-inserted"
    (click)="snackBarRef.dismiss()"
  >
    OK
  </button>
</div>
