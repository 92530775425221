import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

export type FileUploadResponseBadRequestError = {
  error: {
    statusCode?: 400;
    message?: string[];
    error?: string;
  };
};

export type FileUploadResponseUnauthorizedError = {
  error: {
    statusCode: 401;
    message: string;
  };
};

export type FileUploadUnknownError = {
  error: {
    statusCode: number;
    message?: unknown;
  };
};

export type FileUploadResponseError =
  | FileUploadResponseBadRequestError
  | FileUploadResponseUnauthorizedError
  | FileUploadUnknownError;

@Component({
  selector: 'file-validation-snack-bar',
  templateUrl: './file-validation-snack-bar.component.html',
  styleUrls: ['./file-validation-snack-bar.component.scss'],
})
//customized snackbar component to enable download an error file
export class FileValidationSnackBarComponent {
  private maxErrorsLength = 3;
  public validationErrors: string[];

  constructor(
    public snackBarRef: MatSnackBarRef<FileValidationSnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: FileUploadResponseError,
  ) {
    this.validationErrors = this.parseUploadError();
  }

  get csvDownloadLink() {
    const errorList = this.validationErrors.join('\n');
    return `data:text/csv;charset=utf-8,${encodeURIComponent(errorList)}`;
  }

  get visibleErrors() {
    return this.validationErrors.slice(0, this.maxErrorsLength);
  }

  get remainingErrors() {
    return this.validationErrors.length - this.maxErrorsLength;
  }

  //if the upload error had a status 401, returns an authentication message
  private parseUploadError(): string[] {
    const { error } = this.data;
    let message = ['Error when uploading file.  Check the file and try again'];
    switch (error.statusCode) {
      case 401:
        message = [
          `Invalid authentication token, try logging out and logging in again before uploading the file. Error: ${String(
            error.message,
          )}`,
        ];
        break;

      default:
        if (Array.isArray(error.message)) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          message = error.message;
        } else {
          message = [String(error.message)];
        }
        break;
    }
    return message;
  }
}
