import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import {
  FilterDayEnum,
  FilterDays,
  FilterStatus,
  FilterUpdateType,
  GlobalConstants,
} from 'src/app/global-constants';
import {
  Filterable,
  checkFilterValues,
  filterPlainArray,
} from 'src/app/utils/filter-plain-array';
import { MatPaginator } from '@angular/material/paginator';
import {
  FilterableBatchInformation,
  batchToFilterable,
  filterBatchInformation,
} from './filterable-batch';
import {
  debounceTime,
  distinctUntilChanged,
  fromEvent,
  filter,
  tap,
  Subscription,
} from 'rxjs';
import {
  BatchDto,
  BatchRecordStatusDto,
  Status,
} from '@wex-risk/rp-portfolio-utils';
import { RAGApiService } from 'src/app/services/rag-api/rag-api.service';
import { BatchRecordsService } from 'src/app/services/batchrecords/batchrecords.service';

//Used to override MatTableDataSource's default filterPredicate
function staticFilterPredicate(record: BatchDto, filter: string) {
  const map = new Map(JSON.parse(filter) as [string, string][]);
  for (const [key, value] of map) {
    if (typeof value === 'string' && value.toLowerCase() === 'all') {
      continue;
    }
    if (!checkFilterValues(record[key as keyof BatchDto], value)) {
      return false;
    }
  }

  return true;
}
export enum DownloadStatus {
  Preparing = 'Preparing',
  Ready = 'Ready',
  Error = 'Error',
}

/**
 * `ProcessedFilesComponent` is a component that handles the display and interaction
 * with the processed files in the application. It provides functionalities such as
 * pagination and filtering of processed files.
 *
 * The component uses several `ViewChild` decorators to interact with child components
 * in the template, such as paginators and a file search input element.
 *
 * It also defines several properties for page sizes, response statuses, filter days,
 * update types, file statuses, and displayed columns for the processed files table.
 */
@Component({
  selector: 'app-processedfiles',
  templateUrl: './processedfiles.component.html',
  styleUrls: ['./processedfiles.component.scss'],
})
// processed files page
export class ProcessedFilesComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('pendingPaginator') pendingPaginator: MatPaginator;
  @ViewChild('processedPaginator') processedPaginator: MatPaginator;
  @ViewChild('fileSearch') fileSearch: ElementRef<HTMLInputElement>;

  protected pageSizes = [5, 10, 20, 40];
  protected BatchRecordStatusDto = BatchRecordStatusDto;
  protected filterDays = GlobalConstants.filterDays;
  protected filterUpdateTypes = GlobalConstants.filterUpdateType;
  protected filterFileStatus = GlobalConstants.filterFileStatus;
  protected displayedColumns: (
    | keyof FilterableBatchInformation
    | 'actionsColumn'
  )[] = [
    'fileName',
    'status',
    'submittedOn',
    'submittedBy',
    'totalRecords',
    'pendingRecords',
    'successRecords',
    'supressedRecords',
    'failedRecords',
    'actionsColumn',
  ];

  protected downloadStatusEnum = DownloadStatus;
  protected pendingFiles: MatTableDataSource<FilterableBatchInformation>;
  protected processedFiles: MatTableDataSource<FilterableBatchInformation>;
  protected downloadDialogVisible: boolean;
  protected downloadDialogHeader: string;
  protected downloadLink?: string;
  protected downloadStatus?: DownloadStatus;
  searchValue = '';
  selDaysFilter: FilterDays;
  selChangeTypeFilter: FilterUpdateType;
  selFileStatusFilter: FilterStatus;

  private searchInputSub: Subscription;
  private batches: BatchDto[] = [];
  protected totalBatches = 0;

  constructor(
    private ragService: RAGApiService,
    private snackBar: MatSnackBar,
    private batchRecordService: BatchRecordsService,
  ) {
    this.pendingFiles = new MatTableDataSource();
    this.processedFiles = new MatTableDataSource();

    this.pendingFiles.filterPredicate = staticFilterPredicate;
    this.processedFiles.filterPredicate = staticFilterPredicate;

    this.selDaysFilter = this.filterDays.find(
      (x) => x.id === FilterDayEnum.Days30,
    );
    this.selChangeTypeFilter = this.filterUpdateTypes.find(
      (x) => x.id === 'ALL',
    );
    this.selFileStatusFilter = this.filterFileStatus.find(
      (x) => x.id === 'ALL',
    );
  }

  // TOOD: retrieve from input
  private get filter(): Filterable<FilterableBatchInformation> {
    return {
      fileName: this.searchValue,
      submittedOn:
        this.selDaysFilter.id === FilterDayEnum.All
          ? null
          : this.selDaysFilter.id,
      status:
        this.selFileStatusFilter.id === 'ALL'
          ? null
          : this.selFileStatusFilter.id,
      updateTypes:
        this.selChangeTypeFilter.id === 'ALL'
          ? null
          : this.selChangeTypeFilter.id,
    };
  }

  /**
   * This method is called once the component is initialized.
   * It calls the getBatchFiles method to fetch the batch files.
   */
  ngOnInit() {
    this.getBatchFiles().catch(() =>
      console.error('Error getting files during ngOnInit'),
    );
  }

  /**
   * Lifecycle hook that is called after Angular has fully initialized a component's view.
   * Declare any necessary post-initialization actions in this method.
   */
  ngAfterViewInit() {
    this.searchInputSub = fromEvent(this.fileSearch.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => {
          this.filterTables();
        }),
      )
      .subscribe();

    this.pendingFiles.paginator = this.pendingPaginator;
    this.processedFiles.paginator = this.processedPaginator;
  }

  /**
   * This method applies filters to the pending and processed files.
   * It uses the current filter settings to filter the batch information.
   * The filtered batches are then transformed into a filterable format.
   */
  protected filterTables() {
    const filters = this.filter;

    this.pendingFiles.data = filterBatchInformation(
      this.batches.filter((x) => x.status === Status.PROCESSING),
      filters,
    ).map(batchToFilterable);
    this.processedFiles.data = filterBatchInformation(
      this.batches.filter((x) => x.status !== Status.PROCESSING),
      filters,
    ).map(batchToFilterable);
  }

  // skipcq: JS-0041
  private set batchFiles(files: BatchDto[]) {
    this.batches = files
      .slice()
      .sort((a, b) => b.submittedOn.getTime() - a.submittedOn.getTime());

    const pending = filterBatchInformation(files, {
      status: Status.PROCESSING,
    });

    this.pendingFiles.data = pending.map(batchToFilterable);

    this.processedFiles.data = files
      .filter((x) => !pending.includes(x))
      .map(batchToFilterable);
    this.filterTables();
  }

  /**
   * Retrieves batches from the RAG API
   * @returns {Promise<void>} - Returns a promise that resolves when the batch files are fetched. Files are stored in this.batchFiles
   */
  private async getBatchFiles() {
    try {
      const response = await this.ragService.getFiles();
      this.batchFiles = response.files;
      this.totalBatches = response.totalRecords;
    } catch (e) {
      console.error('Error retrieving files');
      console.error(e);
      this.snackBar.open('Error when retrieving files', 'Okay');
      return;
    }

    this.snackBar.open('All files loaded...', 'Okay', {
      duration: 1000,
    });
  }

  /**
   * Retrieves the signed URL to download the file from S3
   * @param batchId ID of the batch being downloaded
   * @param status batch record status to be filtered
   */
  protected async getDownloadLink(
    batchId: string,
    status?: BatchRecordStatusDto,
  ) {
    try {
      this.downloadStatus = DownloadStatus.Preparing;
      this.downloadDialogHeader = 'Preparing download';
      this.downloadDialogVisible = true;
      const downloadResponse = await this.ragService.getDownloadLink(
        batchId,
        status,
      );
      this.downloadStatus = DownloadStatus.Ready;
      this.downloadLink = downloadResponse.downloadUrl;
      this.downloadDialogHeader = 'Download ready';
    } catch (err) {
      this.downloadDialogHeader = 'Error preparing download';
      this.downloadStatus = DownloadStatus.Error;
      console.error('Error trying to get download link', err);
    }
  }

  /**
   * Handles the download modal close event
   */
  protected onModalClose() {
    this.downloadStatus = undefined;
    this.downloadDialogVisible = false;
    this.downloadLink = undefined;
  }
  //export batch results to CSV
  protected async exportToCSV(
    status: BatchRecordStatusDto | null,
    batchId: string,
  ) {
    const batch = this.batches.find((x) => x.id === batchId);

    if (!batch) {
      throw new Error('Batch not found in local cache');
    }

    const records = await this.ragService.getRecords(batchId);

    const exportRecords = filterPlainArray(records, { status });

    this.batchRecordService
      .createSpreadsheet(batch, exportRecords)
      .download(status ? `ONLY_${status}` : 'ALL');
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.searchInputSub.unsubscribe();
  }
}
