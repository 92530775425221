import { Injectable } from '@angular/core';
import { OktaJwtDto } from '@wex-risk/rp-portfolio-utils';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
//Used to provide an instance of the current user after it's data is retrieved from OKTA
export class UserInfoService {
  private _user = new BehaviorSubject<OktaJwtDto>({} as OktaJwtDto);
  user = this._user.asObservable();

  // returns current user info
  getUser() {
    return this._user.value;
  }
  // sets current user once the observable returns data
  setUser(user: OktaJwtDto) {
    this._user.next(user);
  }
}
