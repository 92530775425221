import { Injectable } from '@angular/core';
import { BatchRecordsSpreadsheet } from './batchrecords-spreadsheet';
import { BatchDto, BatchRecordDto } from '@wex-risk/rp-portfolio-utils';

@Injectable({
  providedIn: 'root',
})
/**
 * Provides an injectable class to instantiate BatchRecordsSpreadsheet
 */
export class BatchRecordsService {
  /**
   * Creates a new instance of BatchRecordsSpreadsheet
   * @param batch
   * @param records
   * @returns BatchRecordsSpreadsheet
   */
  // skipcq: JS-0105
  createSpreadsheet(batch: BatchDto, records: BatchRecordDto[]) {
    return new BatchRecordsSpreadsheet(batch, records);
  }
}
