import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ElementRef,
  ViewChild,
  HostListener,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { WorkSheet, utils, read } from 'xlsx';
import {
  FileUploadResponseError,
  FileValidationSnackBarComponent,
} from '../file-validation-snack-bar/file-validation-snack-bar.component';

export type FileUploadFileData = {
  [key: string]: string;
};

export type FileUploadFileDataInfo<T = FileUploadFileData> = {
  row: number;
  dataErrors?: {
    [key in keyof T]: string | undefined;
  };
  data: T;
};

export interface FileUploadEventData<T = FileUploadFileData> {
  fileName: string;
  fileRows: number;
  fileContents: FileUploadFileDataInfo<T>[];
}

export interface FileContentValidationResult<
  T extends object = FileUploadFileData,
> {
  errorMap?: Map<keyof T, string>;
  valid: boolean;
}

export type FileUploadEvent<T = FileUploadFileData> = FileUploadEventData<T> & {
  onFinish: (err?: Error) => void;
};

@Component({
  selector: 'fileupload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
/**
 * Reusable component for uploading files and validating their contents
 * Inputs:
 *  - headers @type {string[]} - Headers to validate against
 *  - validateRow @type {(eventData: FileUploadFileData) => Promise<FileContentValidationResult> | FileContentValidationResult} - Function to validate each row of the file
 *  - cleanupRow @type {(eventData: FileUploadFileData) => FileUploadFileData} - Function to clean each row of the file, if this isn't passed the default cleanup is executed
 * Outputs:
 *  - dataSubmitted @type {EventEmitter<FileUploadEvent>} - Event emitted when the file is submitted
 *  - headersChange @type {EventEmitter<string[]>} - Event emitted when the headers are changed
 */
export class FileUploadComponent implements OnInit {
  @Output() readonly dataSubmitted = new EventEmitter<FileUploadEvent>();
  @ViewChild('fileInput', { static: false })
  fileInput: ElementRef<HTMLInputElement>;
  @Input() headers: string[];
  @Output() readonly headersChange = new EventEmitter<string[]>();
  computedHeaders: string[];
  @Input() validateRow?: (
    eventData: FileUploadFileData,
  ) => Promise<FileContentValidationResult> | FileContentValidationResult;
  @Input() cleanupRow?: (eventData: FileUploadFileData) => FileUploadFileData;

  fileOver = false;
  loading = false;
  fileInfo: FileUploadEventData;
  fileIsValid = false;

  textPrompt = '';
  isCertified = false;
  protected readonly tooltipInfo =
    'By certifying, the requester takes responsibility for the results of this batch request.';

  private readonly snackbarDuration = 30 * 1000;

  constructor(private snackBar: MatSnackBar) {}

  // sets the text in the upload input
  ngOnInit(): void {
    this.textPrompt = this.fileInfo?.fileName ?? 'Please select a file.';
  }

  // On file Select
  async onChange(event: Event) {
    const element = event.target as HTMLInputElement;
    // should never throw - should handle errors inside the method below
    await this.onFileSelected(element.files[0]);
  }

  /**
   * @param {File} file - File selected by the user
   * @returns {Promise<boolean>} Whether the file was successfully validated or not
   */
  private async onFileSelected(file: File) {
    try {
      this.loading = true;
      await this.updateFileData(file);
      this.loading = false;
    } catch (e) {
      let err: Error;
      if (!(e instanceof Error) && !('message' in e)) {
        err = new Error(String(e));
      } else {
        err = e as Error;
      }

      this.openErrorSnackBar({ error: { message: [err.message] } });
      this.resetForm();

      return false;
    }

    return true;
  }

  /**
   *
   * @param {FileUploadFileData} row Row containing the data to be cleaned
   * @returns {FileUploadFileData} Cleaned row
   */
  private doCleanup(row: FileUploadFileData) {
    if (typeof this.cleanupRow === 'function') {
      return this.cleanupRow(row);
    }

    return FileUploadComponent.defaultCleanup(row);
  }

  /**
   * @param {FileUploadFileData} row Row containing the data to be validated
   * @returns {Promise<FileContentValidationResult>} Object containing if the validation results and a Map containing each error
   */
  private async validateFileRow(
    row: FileUploadFileData,
  ): Promise<FileContentValidationResult> {
    if (typeof this.validateRow === 'function') {
      return await this.validateRow(row);
    }
    return { valid: true };
  }

  /**
   *
   * @param {File} file
   */
  private async updateFileData(file: File) {
    const { fileContent, fileRows } = await this.readFileData(file);
    const xlsxRowOffset = 2;

    this.fileIsValid = false;
    let isValid = true;

    const cleanData = fileContent.map<FileUploadFileDataInfo>(
      (row, rowIndex) => ({
        data: this.doCleanup(row),
        row: rowIndex + xlsxRowOffset,
      }),
    );

    for (const row of cleanData) {
      const validation = await this.validateFileRow(row.data);

      if (validation.errorMap?.size > 0) {
        row.dataErrors = Object.fromEntries(validation.errorMap.entries());
        isValid = false;
      }
    }

    this.fileInfo = {
      fileName: file.name,
      fileContents: cleanData,
      fileRows,
    };
    this.computedHeaders = ['rowNumber', ...this.headers];
    this.textPrompt = this.fileInfo.fileName;
    this.fileIsValid = isValid;
  }

  /**
   * @param {FileUploadResponseError} error
   */
  private openErrorSnackBar(error: FileUploadResponseError) {
    this.snackBar.openFromComponent(FileValidationSnackBarComponent, {
      data: error,
    });
  }

  // OnClick of button Upload
  onUpload() {
    this.loading = true;
    this.dataSubmitted.emit({
      ...this.fileInfo,
      onFinish: (err?: Error) => {
        this.resetForm();
        if (err) {
          console.error(err);
          this.snackBar.openFromComponent(FileValidationSnackBarComponent, {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            data: err,
            duration: this.snackbarDuration,
          });
        } else {
          this.snackBar.open('File submitted successfully.', 'Okay', {
            duration: this.snackbarDuration,
          });
        }
      },
    });
  }

  /**
   * Resets the form to its initial state
   */
  private resetForm() {
    this.loading = false;
    this.isCertified = false;
    delete this.fileInfo;
    this.fileInput.nativeElement.value = '';
    this.textPrompt = 'Please select a file.';
  }

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  // Drop listener
  @HostListener('drop', ['$event']) public async ondrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      await this.onFileSelected(files[0]);
    }
  }

  /**
   * Returns object containing the file data, empty are removed and empty cells are set to null
   * @param {WorkSheet}sheet
   * @returns {Object}
   */
  static sheetToJSON(sheet: WorkSheet): { [key: string]: string }[] {
    const json = utils.sheet_to_json<{ [key: string]: unknown }>(sheet, {
      raw: true,
      rawNumbers: true,
      skipHidden: true,
      blankrows: false,
      defval: null,
    });

    return json
      .map((row) => {
        const obj: { [key: string]: undefined | string } = {};
        for (const key in row) {
          if (Object.prototype.hasOwnProperty.call(row, key)) {
            const value = row[key];
            const fixedKey = key.toLowerCase().trim();
            if (value === null || value === '') {
              obj[fixedKey] = undefined;
            } else {
              obj[fixedKey] = String(value).trim();
            }
          }
        }

        return obj;
      })
      .filter(
        (row) =>
          Object.values(row).filter((val) => val !== undefined).length > 0,
      );
  }

  /**
   * Trims each cell in a row
   * @param {FileUploadFileData} fileRow
   * @returns {FileUploadFileData} Trimmed row
   */
  static defaultCleanup = (fileRow: FileUploadFileData): FileUploadFileData => {
    for (const [key, value] of Object.entries(fileRow)) {
      if (typeof value === 'string') {
        fileRow[key] = value.trim();
      }
    }
    return fileRow;
  };

  // Read uploaded file and convert to DTO
  readFileData(
    file: File,
  ): Promise<{ fileContent: FileUploadFileData[]; fileRows: number }> {
    return new Promise((resolve, reject) => {
      if (!this.headers) {
        reject(new Error('No headers provided'));
        return;
      }

      const fileReader = new FileReader();
      fileReader.readAsBinaryString(file);
      fileReader.onload = (event: ProgressEvent<FileReader>) => {
        const binD = event.target.result;
        // read the file
        const wb = read(binD, { type: 'binary', raw: true });
        const firstSheet = wb.Sheets[wb.SheetNames[0]];
        if (!firstSheet) {
          reject(new Error('No sheets available'));
          return;
        }

        // read it into an array of rows
        const data = FileUploadComponent.sheetToJSON(firstSheet);
        if (data.length === 0) {
          reject(new Error('No data found in file'));
          return;
        }
        const firstValue = data[0];

        const missingHeaders = this.headers
          .map((header) => header.toLowerCase())
          .map((header) => {
            return {
              header,
              hasHeader: header in firstValue,
            };
          })
          .filter(({ hasHeader }) => !hasHeader)
          .map(({ header }) => header);

        if (missingHeaders.length > 0) {
          reject(
            new Error(
              `Missing required headers: ${Object.values(missingHeaders).join(
                ', ',
              )}`,
            ),
          );
        }

        resolve({
          fileContent: data,
          fileRows: data.length,
        });
      };

      fileReader.onerror = reject;
    });
  }
}
