<div class="home-content">
  @if (!adminSettings) {
    <div>
      <app-lds-ellipsis [isLoading]="true"></app-lds-ellipsis>
    </div>
  } @else if (!adminSettings?.enabled) {
    <div class="title">Dashboard <span>Last 30 days</span></div>
    <div class="home-cards">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Credit Limit Increase</mat-card-title>
        </mat-card-header>
        @if (cliBatchFiles) {
          <mat-card-content>
            <div class="total-records">
              <span class="record-count">{{ cliBatchFiles.length }}</span>
              <div>total files processed</div>
            </div>
            <div class="sub-total-records">
              <div>
                <span class="record-count success">{{ cliSuccesses }}</span>
                <div>files with all successful records</div>
              </div>
              <div>
                <span class="record-count failure">{{ cliFailures }}</span>
                <div>files with failed records</div>
              </div>
            </div>
            <div class="goto-card-link">
              <button
                color="primary"
                mat-raised-button
                [routerLink]="['/submit']"
              >
                <mat-icon mat-list-icon>add_circle_outline</mat-icon>Credit
                limit increase
              </button>
            </div>
          </mat-card-content>
        }
      </mat-card>

      <mat-card>
        <mat-card-header>
          <mat-card-title>Credit Limit Decrease</mat-card-title>
        </mat-card-header>
        @if (cldBatchFiles) {
          <mat-card-content>
            <div class="total-records">
              <div class="record-count">{{ cldBatchFiles.length }}</div>
              <div>total files processed</div>
            </div>
            <div class="sub-total-records">
              <div>
                <span class="record-count success">{{ cldSuccesses }}</span>
                <div>files with all successful records</div>
              </div>
              <div>
                <span class="record-count failure">{{ cldFailures }}</span>
                <div>files with failed records</div>
              </div>
            </div>
            <div class="goto-card-link">
              <button
                color="primary"
                mat-raised-button
                [routerLink]="['/submit']"
              >
                <mat-icon mat-list-icon fontSet="material-icons-outlined"
                  >do_not_disturb_on</mat-icon
                >Credit limit decrease
              </button>
            </div>
          </mat-card-content>
        }
      </mat-card>
    </div>
  } @else {
    <div class="title">Maintenance</div>
    <div class="home-cards">
      <mat-card>
        <mat-card-header>
          <mat-card-title>System Maintenance</mat-card-title>
        </mat-card-header>
        <mat-card-content class="maintenance-scrollable-content">
          <div class="maintenance-content">
            <mat-icon class="mat-warn">info</mat-icon>
            <div>
              The system is currently under maintenance. Please check back
              later.
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  }
</div>
