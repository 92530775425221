import { BatchDto, BatchRecordDto } from '@wex-risk/rp-portfolio-utils';
import { BatchRecordExport } from 'src/app/models/batch-record-export.model';
import { WorkSheet, WorkBook, utils, writeFile } from 'xlsx';

/**
 * Contains functions to create and download a spreadsheet of batch records.
 */
export class BatchRecordsSpreadsheet {
  private _batchRecords: BatchRecordDto[];

  constructor(
    public readonly batch: BatchDto,
    batchRecords: BatchRecordDto[],
  ) {
    this.batchRecords = batchRecords;
  }

  public get batchRecords() {
    return this._batchRecords;
  }

  private set batchRecords(batchRecords: BatchRecordDto[]) {
    this._batchRecords = batchRecords.slice();
  }

  /**
   * Adds a batch record to the list of records.
   * @param batchRecord
   */
  public addBatchRecord(batchRecord: BatchRecordDto) {
    this.batchRecords.push(batchRecord);
  }

  /**
   * Creates a WorkBook object from the added batch records.
   * @param workSheetName
   * @returns WorkBook
   */
  toWorkBook(workSheetName = 'records') {
    const ws: WorkSheet = utils.json_to_sheet(
      this.batchRecords.map(BatchRecordExport.fromRecord),
    );
    const wb: WorkBook = utils.book_new();

    utils.book_append_sheet(wb, ws, workSheetName);

    return wb;
  }

  /**
   * Generates CSV for download
   * @param listName
   * @returns WorkBook
   */
  download(listName: string) {
    const originalName =
      this.batch.fileName.lastIndexOf('.') === -1
        ? this.batch.fileName
        : this.batch.fileName.slice(0, this.batch.fileName.lastIndexOf('.'));
    const fileName = `${originalName}_SUBMISSION_${
      this.batch.id
    }_${listName.toUpperCase()}.csv`;

    const workBook = this.toWorkBook(listName);

    writeFile(workBook, fileName);

    return workBook;
  }
}
