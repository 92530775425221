<mat-toolbar color="primary">
  <img src="../assets/images/wexlogo.png" class="toolbar-logo" />
  <span>Risk Action Gateway</span>
</mat-toolbar>
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" mode="side" opened="true">
    <mat-nav-list>
      <!--<mat-list-item role="listitem" (click)="onNavToggle()">
        <mat-icon aria-label="toggle menu icon" id="menuIcon" [class.rotated]="isExpanded">menu</mat-icon> 
      </mat-list-item>-->
      @for (link of links; track link) {
        <a
          mat-list-item
          [routerLink]="link.url"
          routerLinkActive="active"
          *ngIf="hasAccess(link.url)"
        >
          <span class="content">
            <mat-icon mat-list-icon class="menu-item-icon">{{
              link.icon
            }}</mat-icon>
            <span class="menu-item-text">{{ link.name }}</span>
            <mat-icon routerLinkActive="active" class="active-menu"
              >keyboard_arrow_right</mat-icon
            >
          </span>
        </a>
      }
      <app-lds-ellipsis [isLoading]="isLoading"></app-lds-ellipsis>
      <a
        mat-list-item
        routerLinkActive="active"
        class="logout-link"
        (click)="signOut()"
        ><span class="content"
          ><mat-icon
            mat-list-icon
            class="menu-item-icon"
            fontSet="material-icons-outlined"
            >logout</mat-icon
          ><span class="menu-item-text">Logout</span
          ><mat-icon routerLinkActive="active" class="active-menu"
            >keyboard_arrow_right</mat-icon
          >
        </span>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content [ngStyle]="{ 'margin-left.px': contentMargin }">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
