import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import {
  BehaviorSubject,
  filter,
  firstValueFrom,
  lastValueFrom,
  Observable,
} from 'rxjs';
import { EnvironmentService } from '../environment.service';
import {
  BatchDto,
  BatchRecordDto,
  BatchRecordSubmissionDto,
  BatchRecordSubmissionResponseDto,
  ExclusionRecordPutDto,
  ExclusionsRecordSubmissionResponseDto,
  FilesResponseDto,
  PaginatedResult,
  TreatmentsSearchParametersDto,
  BatchRecordSubmissionRow,
  AdminSettingsDto,
  BatchRecordStatusDto,
  AdminSettingType,
  AdminSettingsUpdateQueryDto,
} from '@wex-risk/rp-portfolio-utils';
import { ValidationErrorDto } from './dto/validation-error.dto';
import { plainToInstance } from 'class-transformer';
import { UserInfoService } from '../userinfo/userinfo.service';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
//Service for interacting with the RAG API
export class RAGApiService {
  private _adminSettings = new BehaviorSubject<AdminSettingsDto | null>(null);
  constructor(
    private http: HttpClient,
    private readonly envService: EnvironmentService,
    private _userService: UserInfoService,
    private router: Router,
  ) {
    this.handleRouterEvents();
    this.syncAdminSettingsState().catch((error) => console.error(error));
  }

  //Retrieves an observable of the current admin settings
  get adminSettings$(): Observable<AdminSettingsDto | null> {
    return this._adminSettings.asObservable();
  }

  // Retrieves the current admin settings directly
  get adminSettings(): AdminSettingsDto | null {
    return this._adminSettings.value;
  }

  // Set the current admin settings and notifies subscribers
  set adminSettings(settings: AdminSettingsDto | null) {
    this._adminSettings.next(settings);
  }

  //retrieves all batches from RAG API
  async getFiles() {
    return plainToInstance(
      FilesResponseDto,
      await firstValueFrom(
        this.http.get<object>(this.envService.apiPath('treatments/batches')),
      ),
    );
  }

  //retrieves a single batch from RAG API
  async getFile(id: number) {
    return await firstValueFrom(
      this.http.get<BatchDto>(
        this.envService.apiPath(`treatments/batches/${id}`),
      ),
    );
  }

  //retrieves a record by it's guid
  getRecord(guid: string): Promise<BatchRecordDto> {
    return firstValueFrom(
      this.http.get<BatchRecordDto>(
        this.envService.apiPath(`treatments/records/${guid}`),
      ),
    );
  }

  //retrieve download link for a batch
  getDownloadLink(
    batchId: string,
    status?: BatchRecordStatusDto,
  ): Promise<{ downloadUrl: string }> {
    const statusParam = status ? `?status=${status}` : '';
    const url = firstValueFrom(
      this.http.get<{ downloadUrl: string }>(
        this.envService.apiPath(
          `treatments/batches/${batchId}/downloadurl${statusParam}`,
        ),
      ),
    );
    return url;
  }

  //retrieves all records for a batch
  getRecords(batchId: string): Promise<BatchRecordDto[]> {
    return firstValueFrom(
      this.http.get<BatchRecordDto[]>(
        this.envService.apiPath(`treatments/batches/${batchId}/records`),
      ),
    );
  }

  //submits contents from a file that'll be uploaded for validation
  async validateSubmission(submission: BatchRecordSubmissionDto) {
    try {
      return await firstValueFrom(
        this.http.post<{ status: 'success' }>(
          this.envService.apiPath('treatments/batches/submit/validate'),
          submission,
        ),
      );
    } catch (e) {
      console.error(e);
    }
    return undefined;
  }

  //validates a single row from a file that'll be uploaded
  async validateSubmissionRow(
    submission: BatchRecordSubmissionRow,
  ): Promise<
    | { status: 'success' }
    | { status: 'failure'; errors?: ValidationErrorDto[]; message?: string }
  > {
    try {
      return await firstValueFrom(
        this.http.post<{ status: 'success' }>(
          this.envService.apiPath('treatments/batches/submit/validate/row'),
          submission,
        ),
      );
    } catch (e) {
      console.error(e);
      if (e instanceof HttpErrorResponse) {
        if (e.status === 0) {
          return {
            status: 'failure',
            message: 'Network Error',
          };
        } else if (e.status === (HttpStatusCode.BadRequest as number)) {
          // error.message should be ValidationErrorDto[]
          const errors = (e.error as { message: ValidationErrorDto[] }).message;

          return {
            status: 'failure',
            errors,
          };
        } else {
          return {
            status: 'failure',
            message: e.message,
          };
        }
      } else {
        throw e;
      }
    }
  }

  // searches for records in RAG API
  async searchRecords(
    searchParams: TreatmentsSearchParametersDto,
  ): Promise<PaginatedResult<BatchRecordDto>> {
    const queryString = new URLSearchParams(
      Object.entries(searchParams),
    ).toString();
    const batchRecords = await lastValueFrom(
      this.http.get<PaginatedResult<BatchRecordDto>>(
        this.envService.apiPath(`treatments/records?${queryString}`),
      ),
    );
    return batchRecords;
  }

  //uploads a file containing accounts to be excluded from treatments to the RAG API
  async submitExclusions(data: ExclusionRecordPutDto) {
    return await lastValueFrom(
      this.http.put<ExclusionsRecordSubmissionResponseDto>(
        this.envService.apiPath('exclusions'),
        data,
      ),
    );
  }

  //submits a file of treatments to the RAG API
  async submitTreatments(data: BatchRecordSubmissionDto) {
    return await lastValueFrom(
      this.http.post<BatchRecordSubmissionResponseDto>(
        this.envService.apiPath('treatments/batches/submit'),
        data,
      ),
    );
  }

  /**
   * Submits manual review decision to RAG API
   * @param recordId
   * @param decision
   */
  async reviewRecord(recordId: string, decision: string) {
    return await lastValueFrom(
      this.http.patch(
        this.envService.apiPath(`treatments/records/${recordId}/review`),
        { decision },
      ),
    );
  }

  // Method to fetch admin settings from the API
  async fetchAdminSettings(): Promise<AdminSettingsDto> {
    return await lastValueFrom(
      this.http.get<AdminSettingsDto>(
        this.envService.apiPath('admin-settings/flags'),
        {
          params: { type: AdminSettingType.DISABLE_PROCESSING_FILES },
        },
      ),
    );
  }

  //updates a setting in the RAG API
  updateAdminSetting(setting: AdminSettingsDto) {
    const featureFlagQuery: AdminSettingsUpdateQueryDto = {
      type: setting.type,
      enabled: setting.enabled,
    };

    return this.http.post<AdminSettingsDto>(
      this.envService.apiPath('admin-settings/flags'),
      featureFlagQuery,
    );
  }

  //updates a setting Subject in the RAG API
  async syncAdminSettingsState() {
    try {
      const settings = await this.fetchAdminSettings();
      this.adminSettings = settings;
    } catch (error) {
      console.error('Error in syncAdminSettingsState:', error);
    }
  }
  //it should redirect to the home page
  async goHome() {
    await this.router.navigate(['/home']);
  }

  // Handle Router events listener
  private handleRouterEvents() {
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd,
        ),
      )
      .subscribe(() => {
        this.syncAdminSettingsState().catch((error) =>
          console.error(
            'Error updating admin settings on route change:',
            error,
          ),
        );
      });
  }
}
