import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { UserInfoService } from './services/userinfo/userinfo.service';
import { inject } from '@angular/core';
import { filter, firstValueFrom, map } from 'rxjs';
import { AccessRightsDto, OktaJwtDto } from '@wex-risk/rp-portfolio-utils';

export type RoleAuthorizationGuardParams = {
  data: {
    requiredAccessRights: AccessRightsDto[];
  };
};

/**
 * Guard used to validate if the user has access to the route based on the groups it's assigned to.
 * @param route Object containing information about the route and the data passed from the router.
 * @returns Promise that resolves into true(authorized) or false(not authorized).
 */
export const roleAuthorizationGuard: CanActivateFn = async (
  route: ActivatedRouteSnapshot & RoleAuthorizationGuardParams,
) => {
  const userService = inject(UserInfoService);
  const router = inject(Router);
  return firstValueFrom(
    userService.user.pipe(
      filter((user) => user instanceof OktaJwtDto),
      map((user) => {
        const hasAccess = user.hasAnyAccessRights(
          ...route.data.requiredAccessRights,
        );

        if (!hasAccess) {
          console.error('Unauthorized, redirecting to home page');
          router.navigate(['home']).catch((e) => {
            console.error('Failed to redirect to home page');
            console.error(e);
          });
        }

        return hasAccess;
      }),
    ),
  );
};
