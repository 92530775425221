import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatListItem } from '@angular/material/list';

@Component({
  selector: 'app-lds-ellipsis',
  styleUrl: './lds-ellipsis.component.scss',
  templateUrl: './lds-ellipsis.component.html',
  standalone: true,
  imports: [CommonModule, MatListItem],
})
export class LdsEllipsisComponent {
  @Input() public isLoading: boolean;
  constructor() {}
}
