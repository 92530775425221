<div class="upload-container">
  <h1>Submit Bulk Account Actions</h1>
  <div class="moreInfo">
    <span class="title">Requester</span>
    <span class="username">{{ user.name }}</span>
    <span class="email">{{ user.emailAddress }}</span>
    <span class="template-controls">
      <button class="button-template" (click)="exportTemplate()">
        Download Template
      </button>
      <mat-icon
        class="info-icon"
        matTooltip="Click here for more information about the template."
        matTooltipPosition="right"
        (click)="openInfoModal()"
        >info
      </mat-icon>
    </span>
  </div>
  <fileupload
    [(headers)]="headers"
    [validateRow]="validateTreatment"
    (dataSubmitted)="onDataSubmitted($event)"
    class="file-upload"
  ></fileupload>
</div>
