import { BatchRecordUpdateType, Status } from '@wex-risk/rp-portfolio-utils';

export type FilterDays = {
  id: FilterDayEnum;
  name: string;
};

export type FilterUpdateTypeEnum = BatchRecordUpdateType | 'ALL';
export type FilterStatusEnum = Status | 'ALL';

export type FilterUpdateType = {
  id: FilterUpdateTypeEnum;
  name: string;
};

export type FilterStatus = {
  id: FilterStatusEnum;
  name: string;
};

export enum LineOfBusinessEnum {
  'NAF' = 'NAF',
  'OTR' = 'EFSLLC',
}

export enum FilterDayEnum {
  'Days7' = 7,
  'Days30' = 30,
  'Days45' = 45,
  'Days90' = 90,
  'Days180' = 180,
  'All' = 0,
}

export const alwaysVisiblePaths = ['/home', '/'];

// Static filter values
export const GlobalConstants = {
  filterDays: [
    { id: FilterDayEnum.Days7, name: 'Last 7 Days' },
    { id: FilterDayEnum.Days30, name: 'Last 30 Days' },
    { id: FilterDayEnum.Days180, name: 'Last 180 Days' },
    { id: FilterDayEnum.All, name: 'All' },
  ] as FilterDays[],
  filterUpdateType: [
    { id: BatchRecordUpdateType.CLI, name: 'CLI' },
    { id: BatchRecordUpdateType.CLD, name: 'CLD' },
    { id: BatchRecordUpdateType.SUSPENSION, name: 'Suspension' },
    { id: BatchRecordUpdateType.MANUAL_REVIEW, name: 'Manual Review' },
    { id: 'ALL', name: 'ALL' },
  ] as FilterUpdateType[],
  filterFileStatus: [
    { id: Status.ACCEPTED, name: 'Accepted' },
    { id: Status.FAILED, name: 'Failed' },
    { id: Status.PROCESSING, name: 'Processing' },
    { id: 'ALL', name: 'All Statuses' },
  ] as FilterStatus[],
};
