import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslocoRootModule } from './transloco-root.module';
import { AppMaterialModule } from './app-material/app-material.module';
import { AppRoutingModule } from './app-routing.module';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { SubmitComponent } from './pages/submit/submit.component';
import { HomeComponent } from './pages/home/home.component';
import { ProcessedFilesComponent } from './pages/processedfiles/processedfiles.component';
import { FormsModule } from '@angular/forms';
import { OrderByPipe } from './pipes/orderby.pipe';
import { FilterPipe } from './pipes/filter.pipe';

import { OKTA_AUTH, OktaAuthModule } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { AuthInterceptor } from './auth.interceptor';
import { environment } from 'src/environments/environment';
import { FileValidationSnackBarComponent } from './components/file-validation-snack-bar/file-validation-snack-bar.component';
import { ExclusionComponent } from './pages/exclusion/exclusion.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatIconModule } from '@angular/material/icon';
import { FileInfoModalComponent } from './components/file-info-modal/file-info-modal.component';
import { SpinnerComponent } from './components/material/spinner/spinner.component';
import { DialogModule } from '@wexinc/phoenix-angular-17-components';
import { LdsEllipsisComponent } from './components/lds-ellipsis/lds-ellipsis.component';

const oktaAuth = new OktaAuth({
  issuer: environment.oktaIssuer, // "https://wexinc.oktapreview.com/oauth2/aus1h7jvzriP5gqJI0h8",
  clientId: environment.oktaClientId, // "0oa1h7jpl38MooRyX0h8",
  redirectUri: environment.oktaRedirectUri, // window.location.origin + "/login/callback", //"http://localhost:4200/login/callback",
  logoutUrl: environment.oktaLogoutUrl, // "https://wexinc.oktapreview.com",
  pkce: environment.oktaPkce,
});

@NgModule({
  declarations: [
    AppComponent,
    FileUploadComponent,
    FileInfoModalComponent,
    SubmitComponent,
    ExclusionComponent,
    HomeComponent,
    ProcessedFilesComponent,
    OrderByPipe,
    FilterPipe,
    FileValidationSnackBarComponent,
  ],
  imports: [
    SpinnerComponent,
    LdsEllipsisComponent,
    AppMaterialModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    OktaAuthModule.forRoot({ oktaAuth }),
    HttpClientModule,
    TranslocoRootModule,
    FormsModule,
    FontAwesomeModule,
    MatIconModule,
    DialogModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: OktaAuth, useExisting: OKTA_AUTH },
  ],
  bootstrap: [AppComponent],
})
// skipcq: JS-D1001, JS-0327
export class AppModule {}
