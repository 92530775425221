import { Component, Input } from '@angular/core';
import {
  MatProgressSpinnerModule,
  ProgressSpinnerMode,
} from '@angular/material/progress-spinner';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  standalone: true,
  imports: [MatProgressSpinnerModule],
})
/**
 * Spinner component
 * Inputs:
 *  - mode @type {ProgressSpinnerMode}
 *  - value @type {number}
 * Example usage:
 *  - <app-spinner [mode]="determinate" [value]="value"></app-spinner>
 */
export class SpinnerComponent {
  @Input()
  mode: ProgressSpinnerMode;

  @Input()
  value?: number;

  @Input()
  width = '100px';
}
