<div class="upload-container">
  <h1>Update Account Exclusions</h1>
  <div class="moreInfo">
    <span class="title">Requester</span>
    <span class="username">{{ user?.name }}</span>
    <span class="email">{{ user?.emailAddress }}</span>
    <button class="button-template" (click)="exportTemplate()">
      Download Template
    </button>
  </div>
  <fileupload
    [(headers)]="headers"
    (dataSubmitted)="onDataSubmitted($event)"
    class="file-upload"
    [validateRow]="validateRow"
  ></fileupload>
</div>
