import {
  BatchRecordSubmissionRow,
  BatchRecordUpdateType,
  PlatformType,
  convertToPlatformType,
} from '@wex-risk/rp-portfolio-utils';

export enum SpreadsheetSubmissionColumnNames {
  platform = 'platform',
  update_type = 'update_type',
  account_number = 'account_number',
  account_group = 'account_group',
  credit_limit = 'credit_limit',
  cash_advance_limit = 'cash_advance_limit',
  money_code_limit = 'money_code_limit',
  comment = 'comment',
  customer_status = 'customer_status',
  template_type = 'template_type',
  reason_code_1 = 'reason_code_1',
  reason_code_2 = 'reason_code_2',
  reason_code_3 = 'reason_code_3',
  reason_code_4 = 'reason_code_4',
  billing_currency = 'billing_currency',
}

export type SpreadsheetSubmissionRow = {
  [key in SpreadsheetSubmissionColumnNames]?: string;
};

export const submissionDtoToSpreadsheetColumn = new Map<
  keyof BatchRecordSubmissionRow,
  keyof SpreadsheetSubmissionRow
>([
  ['platform', SpreadsheetSubmissionColumnNames.platform],
  ['updateType', SpreadsheetSubmissionColumnNames.update_type],
  ['customerId', SpreadsheetSubmissionColumnNames.account_number],
  ['businessUnit', SpreadsheetSubmissionColumnNames.account_group],
  ['carrierId', SpreadsheetSubmissionColumnNames.account_group],
  ['creditLimit', SpreadsheetSubmissionColumnNames.credit_limit],
  ['cashAdvanceLimit', SpreadsheetSubmissionColumnNames.cash_advance_limit],
  ['moneyCodeLimit', SpreadsheetSubmissionColumnNames.money_code_limit],
  ['comment', SpreadsheetSubmissionColumnNames.comment],
  ['customerStatus', SpreadsheetSubmissionColumnNames.customer_status],
  ['creditTemplateType', SpreadsheetSubmissionColumnNames.template_type],
  ['firstReasonCode', SpreadsheetSubmissionColumnNames.reason_code_1],
  ['secondReasonCode', SpreadsheetSubmissionColumnNames.reason_code_2],
  ['thirdReasonCode', SpreadsheetSubmissionColumnNames.reason_code_3],
  ['fourthReasonCode', SpreadsheetSubmissionColumnNames.reason_code_4],
  ['billingCurrency', SpreadsheetSubmissionColumnNames.billing_currency],
]);

/**
 * Converts a Spreadsheet row to a BatchRecordSubmissionRow without validation
 * @param data A row from a spreadsheet
 * @returns A BatchRecordSubmissionRow that is not yet validated
 */
export function spreadsheetToSubmissionDto(
  data: SpreadsheetSubmissionRow,
): BatchRecordSubmissionRow {
  const submissionRow = <BatchRecordSubmissionRow>{
    platform: convertToPlatformType(data.platform),
    updateType: data.update_type as BatchRecordUpdateType,
    customerId: data.account_number,
    businessUnit: data.account_group,
    carrierId: data.account_group,
    creditLimit: data.credit_limit,
    cashAdvanceLimit: data.cash_advance_limit,
    moneyCodeLimit: data.money_code_limit,
    comment: data.comment,
    customerStatus: data.customer_status,
    creditTemplateType: data.template_type,
    firstReasonCode: data.reason_code_1,
    secondReasonCode: data.reason_code_2,
    thirdReasonCode: data.reason_code_3,
    fourthReasonCode: data.reason_code_4,
    billingCurrency: data.billing_currency,
  };

  /* remove carrierId or businessUnit if platform is not otr or naf */
  if (submissionRow.platform !== PlatformType.otr) {
    delete submissionRow.carrierId;
  }
  if (submissionRow.platform !== PlatformType.naf) {
    delete submissionRow.businessUnit;
  }

  return submissionRow;
}
