import { EnvironmentType } from './environment-type';

export const environment = <EnvironmentType>{
  production: false,
  environmentName: 'Development',
  baseApiUrl: 'https://riskplatform-dev.wexapi.com/api/platform/rag/',
  baseR360ApiUrl: 'https://riskplatform-dev.wexapi.com/api/platform/r360/',
  baseRiskProfileServiceAPIUrl:
    'https://riskplatform-dev.wexapi.com/api/platform/risk-profile/',
  infoModalDate: new Date('2024-07-31'),
  ignoreScopes: false,
  oktaIssuer: 'https://riskplatform-dev-wex.oktapreview.com/oauth2/default',
  oktaClientId: '0oa7itrsioIvChcfo1d7',
  oktaRedirectUri: `${window.location.origin}/login/callback`,
  oktaLogoutUrl: 'https://riskplatform-dev-wex.oktapreview.com',
  oktaPkce: true,
};
