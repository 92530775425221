<h2 class="title">Submitted Files</h2>
<mat-accordion>
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon>filter_list</mat-icon> Filters
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="dense-2 search">
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-icon matSuffix>search</mat-icon>
        <input
          matInput
          placeholder="Search by file name"
          id="fileSearch"
          [(ngModel)]="searchValue"
          #fileSearch
        />
      </mat-form-field>
    </div>
    <div class="dense-2 dropdowns">
      <mat-select
        #filterDaysSel
        placeholder="Select Days"
        (selectionChange)="filterTables()"
        [(ngModel)]="selDaysFilter"
      >
        <mat-option *ngFor="let item of filterDays" [value]="item">
          {{ item.name }}
        </mat-option>
      </mat-select>
      <mat-select
        #filterCreditChangeTypeSel
        placeholder=""
        (selectionChange)="filterTables()"
        [(ngModel)]="selChangeTypeFilter"
      >
        <mat-option *ngFor="let item of filterUpdateTypes" [value]="item">
          {{ item.name }}
        </mat-option>
      </mat-select>
      <mat-select
        #filterFileStatusSel
        placeholder=""
        (selectionChange)="filterTables()"
        [(ngModel)]="selFileStatusFilter"
      >
        <mat-option *ngFor="let item of filterFileStatus" [value]="item">
          {{ item.name }}
        </mat-option>
      </mat-select>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<mat-accordion multi="true">
  <mat-expansion-panel class="table-panel" expanded>
    <mat-expansion-panel-header class="table-panel-header">
      <mat-panel-title>
        <div style="font-size: 24px">Pending</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    @if (!pendingFiles || pendingFiles.data.length === 0) {
      <div style="padding-left: 24px">0 Files Pending</div>
    }
    <mat-table [dataSource]="pendingFiles">
      <ng-container matColumnDef="fileName">
        <mat-header-cell *matHeaderCellDef class="w-header">
          FILE NAME
        </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.fileName }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef class="w-header">
          STATUS
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span [ngClass]="element.status.toLowerCase()"> Pending</span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="submittedOn">
        <mat-header-cell *matHeaderCellDef class="w-header">
          DATE SUBMITTED</mat-header-cell
        >
        <mat-cell *matCellDef="let element">
          {{ element.submittedOn | date: 'mediumDate' }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="submittedBy">
        <mat-header-cell *matHeaderCellDef class="w-header">
          REQUESTER
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.submittedBy }}
          <span class="email">{{ element.submittedByEmail }}</span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="totalRecords">
        <mat-header-cell *matHeaderCellDef class="w-header">
          RECORDS
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.totalRecords }}
          <mat-icon (click)="getDownloadLink(element.id)" class="download-icon">
            vertical_align_bottom</mat-icon
          >
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="completedRecords">
        <mat-header-cell *matHeaderCellDef class="w-header">
          COMPLETED
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.completedRecords }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="pendingRecords">
        <mat-header-cell *matHeaderCellDef class="w-header">
          <mat-icon class="status-dot pending">fiber_manual_record</mat-icon>
          PENDING
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-icon class="status-dot pending">fiber_manual_record</mat-icon
          >{{ element.pendingRecords ?? 0 }}
          <mat-icon
            *ngIf="element.pendingRecords > 0"
            (click)="
              getDownloadLink(element.id, BatchRecordStatusDto.PROCESSING)
            "
            class="download-icon"
          >
            vertical_align_bottom</mat-icon
          >
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="supressedRecords">
        <mat-header-cell *matHeaderCellDef class="w-header">
          <mat-icon class="status-dot">fiber_manual_record</mat-icon>
          SUPRESSED
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-icon class="status-dot supressed">fiber_manual_record</mat-icon
          >{{ element.supressedRecords ?? 0 }}
          <mat-icon
            *ngIf="element.supressedRecords > 0"
            (click)="
              getDownloadLink(element.id, BatchRecordStatusDto.SUPPRESSED)
            "
            class="download-icon"
          >
            vertical_align_bottom</mat-icon
          >
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="successRecords">
        <mat-header-cell *matHeaderCellDef class="w-header">
          <mat-icon class="status-dot completed">fiber_manual_record</mat-icon>
          SUCCESS
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-icon class="status-dot completed">fiber_manual_record</mat-icon>
          {{ element.successRecords }}
          <mat-icon
            *ngIf="element.successRecords > 0"
            (click)="getDownloadLink(element.id, BatchRecordStatusDto.ACCEPTED)"
            class="download-icon"
          >
            vertical_align_bottom</mat-icon
          >
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="failedRecords">
        <mat-header-cell *matHeaderCellDef class="w-header">
          <mat-icon class="status-dot failed">fiber_manual_record</mat-icon
          >FAILED
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-icon class="status-dot failed">fiber_manual_record</mat-icon
          >{{ element.failedRecords }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actionsColumn">
        <mat-header-cell *matHeaderCellDef class="w-header"> </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="icon-button"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <!-- <button mat-menu-item (click)="exportToCSV(null, element.id)"> -->
            <button mat-menu-item (click)="getDownloadLink(element.id)">
              <span>Download original file</span>
            </button>
            <button
              mat-menu-item
              (click)="
                getDownloadLink(element.id, BatchRecordStatusDto.ACCEPTED)
              "
              [disabled]="element.completedRecords === 0"
            >
              <span>Download accepted records</span>
            </button>
            <button
              mat-menu-item
              (click)="
                getDownloadLink(element.id, BatchRecordStatusDto.PROCESSING)
              "
              [disabled]="element.pendingRecords === 0"
            >
              <span>Download pending records</span>
            </button>
            <button
              mat-menu-item
              (click)="getDownloadLink(element.id, BatchRecordStatusDto.FAILED)"
              [disabled]="element.failedRecords === 0"
            >
              <span>Download failed records</span>
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <div [hidden]="!pendingFiles || pendingFiles.data.length === 0">
      <mat-paginator
        #pendingPaginator
        [pageSizeOptions]="pageSizes"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel class="table-panel" expanded>
    <mat-expansion-panel-header class="table-panel-header">
      <mat-panel-title>
        <div style="font-size: 24px">Processed</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    @if (processedFiles && processedFiles.data.length === 0) {
      <div style="padding-left: 24px">0 Files Processed</div>
    }
    <mat-table
      [dataSource]="processedFiles"
      *ngIf="processedFiles && processedFiles.data.length > 0"
    >
      <ng-container matColumnDef="fileName">
        <mat-header-cell *matHeaderCellDef> FILE NAME </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.fileName }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef> STATUS </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span [ngClass]="element.status.toLowerCase()"> Complete</span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="submittedOn">
        <mat-header-cell *matHeaderCellDef> DATE SUBMITTED</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.submittedOn | date: 'mediumDate' }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="submittedBy">
        <mat-header-cell *matHeaderCellDef> REQUESTER </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.submittedBy }} <br />
          <span class="email">{{ element.submittedByEmail }}</span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="totalRecords">
        <mat-header-cell *matHeaderCellDef> RECORDS </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.totalRecords }}
          <mat-icon (click)="getDownloadLink(element.id)" class="download-icon">
            vertical_align_bottom</mat-icon
          >
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="pendingRecords" [style.display]="'none'">
        <mat-header-cell
          *matHeaderCellDef
          [style.display]="'none'"
        ></mat-header-cell>
        <mat-cell *matCellDef="let element" [style.display]="'none'"></mat-cell>
      </ng-container>
      <ng-container matColumnDef="completedRecords">
        <mat-header-cell *matHeaderCellDef [style.display]="'none'"
          ><mat-icon class="status-dot completed">fiber_manual_record</mat-icon>
          COMPLETED
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [style.display]="'none'"
          ><mat-icon class="status-dot completed">fiber_manual_record</mat-icon>
          {{ element.completedRecords }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="supressedRecords">
        <mat-header-cell *matHeaderCellDef>
          <mat-icon class="status-dot">fiber_manual_record</mat-icon>
          SUPRESSED
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-icon class="status-dot supressed">fiber_manual_record</mat-icon
          >{{ element.supressedRecords ?? 0 }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="successRecords">
        <mat-header-cell *matHeaderCellDef>
          <mat-icon class="status-dot completed">fiber_manual_record</mat-icon>
          SUCCESS
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-icon class="status-dot completed">fiber_manual_record</mat-icon>
          {{ element.successRecords }}
          <mat-icon
            *ngIf="element.successRecords > 0"
            (click)="getDownloadLink(element.id, BatchRecordStatusDto.ACCEPTED)"
            class="download-icon"
          >
            vertical_align_bottom</mat-icon
          >
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="failedRecords">
        <mat-header-cell *matHeaderCellDef
          ><mat-icon class="status-dot failed">fiber_manual_record</mat-icon>
          FAILED
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-icon class="status-dot failed">fiber_manual_record</mat-icon
          >{{ element.failedRecords }}
          <mat-icon
            *ngIf="element.failedRecords > 0"
            (click)="getDownloadLink(element.id, BatchRecordStatusDto.FAILED)"
            class="download-icon"
          >
            vertical_align_bottom</mat-icon
          ></mat-cell
        >
      </ng-container>
      <ng-container matColumnDef="actionsColumn">
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="icon-button"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="getDownloadLink(element.id)">
              <span>Download original file</span>
            </button>
            <button
              mat-menu-item
              (click)="
                getDownloadLink(element.id, BatchRecordStatusDto.ACCEPTED)
              "
              [disabled]="element.completedRecords === 0"
            >
              <span>Download accepted records</span>
            </button>
            <button
              mat-menu-item
              (click)="getDownloadLink(element.id, BatchRecordStatusDto.FAILED)"
              [disabled]="element.failedRecords === 0"
            >
              <span>Download failed records</span>
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <div [hidden]="!processedFiles || processedFiles.data.length === 0">
      <mat-paginator
        #processedPaginator
        [pageSizeOptions]="pageSizes"
        showFirstLastButtons
      ></mat-paginator>
    </div>
  </mat-expansion-panel>
</mat-accordion>
<p-dialog
  [header]="downloadDialogHeader"
  [(visible)]="downloadDialogVisible"
  [modal]="true"
  [closable]="downloadStatus !== downloadStatusEnum.Preparing"
  (onHide)="onModalClose()"
  appendTo="body"
  styleClass="download-dialog"
>
  <app-lds-ellipsis
    [isLoading]="true"
    *ngIf="downloadStatus === downloadStatusEnum.Preparing"
  ></app-lds-ellipsis>
  <ng-container *ngIf="downloadStatus === downloadStatusEnum.Ready">
    File is ready
    <a target="_blank" style="text-decoration: underline" [href]="downloadLink">
      click here to download it
    </a>
  </ng-container>
  <ng-container *ngIf="downloadStatus === downloadStatusEnum.Error">
    An error occurred when trying to generate the download link
  </ng-container>
</p-dialog>
